import React, { FC, useCallback, useReducer, useRef } from "react";
import {
  CaseConfiguration,
  caseOrTaskMessage,
  UiMessageKey,
} from "../shared/types";
import { useAppConfiguration } from "../shared/contexts/AppConfiguration";
import { ReportSettings } from "../shared/types/Reports";
import { NameValuePair } from "../shared/types/General";
import { ActionButtonWidget } from "../shared/components/widgets/ActionButtonWidget";
import { GeneralIcons } from "../shared/components/Icons";
import { DropdownWidget } from "../shared/components/widgets/DropdownWidget";
import { TextWidget } from "../shared/components/widgets/TextWidget";
import { SpinnerDotted } from "spinners-react";

export interface ReportSettingsEditorProps {
  /**
   * A list of available template names
   */
  templateNames: NameValuePair[];

  /**
   * Currently selected/active case configuration
   */
  settings: ReportSettings;

  /**
   * Whether the save button should be enabled
   */
  saveEnabled: boolean;

  /**
   * Whether the save as button should be enabled
   */
  saveAsEnabled: boolean;

  /**
   * State that can be passed down from the parent container, indicating that a report is currently running
   */
  reportRunning: boolean;

  /**
   * Callback fired when the selected case configuration reference is changed
   * @param ref
   */
  onCaseRefChanged: (caseRef: string) => void;

  /**
   * Callback fired when the selected template name is changed
   * @param ref
   */
  onTemplateTitleChanged: (templateTitle: string) => void;

  /**
   * Callback fired when the current template is to be saved as a new template
   * @param templateTitle
   */
  onSaveAsNewTemplate: (templateTitle: string) => void;

  /**
   * Callback fired when the current template is to be saved/updated
   */
  onSaveTemplate: () => void;

  /**
   * Callback fired when the current report should be run
   */
  onRunReport: () => void;

  /**
   * Indicator bool so that the editor knows that the settings are currently being updated
   */
  updating: boolean;
}

/**
 * State for the report filter
 */
interface ReportSettingsEditorState {
  showTemplateName: boolean;
  templateNameEnabled: boolean;
  templateName?: string;
}

export const ReportSettingsEditor: FC<ReportSettingsEditorProps> = (props) => {
  const appConfig = useAppConfiguration();
  const templateNameRef = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer(reducer, {
    showTemplateName: false,
    templateName: undefined,
    templateNameEnabled: false,
  });

  // all reduction happens here
  function reducer(
    state: ReportSettingsEditorState,
    action: any,
  ): ReportSettingsEditorState {
    const { type, payload } = action;
    switch (type) {
      case "toggleTemplateName": {
        return {
          ...state,
          showTemplateName: payload,
        };
      }
      case "updateTemplateName": {
        return {
          ...state,
          templateName: payload,
        };
      }
    }
    return state;
  }

  // hook widget events here and translate to state changes
  const onWidgetValueChange = useCallback(
    (tag: string, value: any) => {
      switch (tag) {
        case "caseRef":
          props.onCaseRefChanged(value);
          return;
        case "templateName":
          props.onTemplateTitleChanged(value);
          return;
      }
    },
    [props],
  );

  return (
    <div className={"report-form"}>
      <div className={"report-form-row"}>
        {appConfig.loaded && (
          <DropdownWidget
            disabled={false}
            tag="caseRef"
            onValueChange={onWidgetValueChange}
            initialSelection={props.settings.caseRef}
            allowBlank={false}
            label={"Target Case Type"}
            items={appConfig.caseConfigurations}
            labelCallback={(config) =>
              caseOrTaskMessage(
                config as CaseConfiguration,
                UiMessageKey.TitleSingular,
              )
            }
            valueCallback={(config) => (config as CaseConfiguration).ref}
          />
        )}
      </div>

      <div className={"report-form-row"}>
        {appConfig.loaded && (
          <DropdownWidget
            disabled={false}
            tag="templateName"
            onValueChange={onWidgetValueChange}
            initialSelection={props.settings.templateTitle}
            allowBlank={false}
            label={"Report Template"}
            items={props.templateNames}
            labelCallback={(config) => (config as NameValuePair).name}
            valueCallback={(config) => (config as NameValuePair).value}
          />
        )}

        <ActionButtonWidget
          disabled={props.saveEnabled ? !props.saveEnabled : true}
          tag={"save"}
          label={"Save"}
          icon={GeneralIcons.Disk(undefined)}
          onClick={(tag) => {
            props.onSaveTemplate();
          }}
        />

        <TextWidget
          ref={templateNameRef}
          disabled={props.saveAsEnabled ? !props.saveAsEnabled : true}
          tag={"template_name"}
          label={"New Template Name"}
          prompt={"Enter a template name..."}
          onValueChange={(tag, value) =>
            dispatch({
              type: "updateTemplateName",
              payload: value,
            })
          }
        />

        <ActionButtonWidget
          disabled={props.saveAsEnabled ? !props.saveAsEnabled : true}
          tag={"save_as"}
          label={
            state.templateName
              ? `Save as "${state.templateName}"`
              : "Save as new template..."
          }
          icon={
            state.templateName
              ? GeneralIcons.Tick("included-form-tick shift-down-1")
              : GeneralIcons.Cross("excluded-form-cross shift-down-2")
          }
          onClick={(tag) => {
            if (!state.templateName) {
              dispatch({
                type: "toggleTemplateName",
                payload: true,
              });
              if (
                templateNameRef.current &&
                (!state.templateName || state.templateName.length === 0)
              ) {
                templateNameRef.current.focus();
              }
            }

            if (state.templateName) {
              props.onSaveAsNewTemplate(state.templateName);
            }
          }}
        />

        {props.updating && (
          <div className={"widget"}>
            <SpinnerDotted size={24} />
          </div>
        )}
      </div>
      <hr className={"report-form-divider"} />
      <div className={"report-form-row justify-content-left"}>
        {props.reportRunning && (
          <div className={"widget"}>
            <SpinnerDotted size={24} />
          </div>
        )}
        {!props.reportRunning && (
          <ActionButtonWidget
            icon={GeneralIcons.Play("colour-green")}
            onClick={() => {
              props.onRunReport();
            }}
            tag={"runReport"}
            label={"Run Report"}
            disabled={false}
          />
        )}
      </div>
    </div>
  );
};
