import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { Page, PageNav } from "../shared/Page";
import {
  AppConfiguration,
  useAppConfiguration,
} from "../shared/contexts/AppConfiguration";
import {
  ReportingConfiguration,
  useReportingConfiguration,
} from "../shared/contexts/ReportingConfiguration";
import { ReportSettingsEditor } from "./ReportSettingsEditor";
import {
  SelectableReportFieldSet,
  mapFormFieldsToReportFields,
  ReportFilter,
  ReportSettings,
  ReportTemplate,
  SelectionStatus,
  buildDefaultTaskFormInclusionMap,
  buildDefaultCaseFormInclusionMap,
  mutateTemplateFieldSet,
  mutateReportFilter,
  updateFieldSelectionStatus,
  updateFieldLabel,
  toggleCaseFormInclusion,
  toggleTaskFormInclusion,
} from "../shared/types/Reports";
import { ReportTemplateEditor } from "./ReportTemplateEditor";
import { ReportStateDebug } from "./ReportStateDebug";
import { NameValuePair } from "../shared/types/General";
import {
  CaseConfiguration,
  caseTaskConfigurations,
  latestCaseConfiguration,
  TaskConfiguration,
} from "../shared/types";
import { Collapsible } from "../shared/components/Collapsible";
import _ from "lodash";
import useAxios from "axios-hooks";
import { ReportFilterEditor } from "./ReportFilterEditor";
import { GeneralIcons } from "../shared/components/Icons";
import { areObjectsEqual } from "../shared/functions/GeneralUtils";
import { newUUID } from "../shared/functions";
import moment from "moment/moment";

/**
 * Props for the export page
 */
export interface ReportGenerationPageProps { }

/**
 * We have some reasonably complex state here, so we use reduction to manage it
 */
export interface ReportGenerationPageState {
  /**
   * Whether the current template is marked as dirty
   */
  templateDirty: boolean;

  /**
   * A list of available template names, these will be dynamically loaded
   */
  availableTemplates: NameValuePair[];

  /**
   * General report settings
   */
  settings: ReportSettings;

  /**
   * The [CaseConfiguration] that will be used to drive the report generation
   */
  filter?: ReportFilter;

  /**
   * The currently active [[ReportTemplate]]
   */
  template?: ReportTemplate;

  /**
   * The baseline template (to check for dirty states)
   */
  baseLineTemplate?: ReportTemplate;

  /**
   * The currently active case configuration
   */
  caseConfig?: CaseConfiguration;

  /**
   * Currently active list of task configurations
   */
  taskConfigs?: (SelectionStatus & TaskConfiguration)[];

  /**
   * Whether we are currently saving
   */
  saving: boolean;

  /**
   * Whether we are currently running a report
   */
  running: boolean;
}

/**
 * Helper to pivot task configurations into a form field structure
 * @param configs
 */
function buildTemplateFormFieldsFromTaskConfigurations(
  configs: TaskConfiguration[],
): SelectableReportFieldSet {
  const formFields: SelectableReportFieldSet = {};
  // build a form field structure, top-level keys are form references
  for (let config of configs) {
    const key = `${config.ref}_${config.version}`;
    formFields[key] = mapFormFieldsToReportFields(config.form.schema);
  }
  return formFields;
}

/**
 * Takes a case configuration and builds a valid form field structure
 * @param config
 */
function buildTemplateFormFieldsFromCaseConfiguration(
  config: CaseConfiguration,
): SelectableReportFieldSet {
  const formFields: SelectableReportFieldSet = {};
  const key = `${config.ref}_${config.version}`;
  formFields[key] = mapFormFieldsToReportFields(config.form.schema);
  return formFields;
}

/**
 * Construct a default report template with all table fields initially selected
 */
function defaultTemplate(
  appConfig: AppConfiguration,
  reportConfig: ReportingConfiguration,
  caseConfig: CaseConfiguration,
): ReportTemplate {
  // we need task configurations in order to set the default form template fields
  const taskConfigurations = caseTaskConfigurations(appConfig, caseConfig);

  return {
    name: "Default",
    coreCaseFormFields:
      buildTemplateFormFieldsFromCaseConfiguration(caseConfig),
    coreTaskFormFields:
      buildTemplateFormFieldsFromTaskConfigurations(taskConfigurations),
    coreTableFields: {
      cases: reportConfig.caseTableFields.map((item) => {
        return { ...item, selected: true };
      }),
      tasks: reportConfig.taskTableFields.map((item) => {
        return { ...item, selected: true };
      }),
    },
    taskFormInclusions: buildDefaultTaskFormInclusionMap(taskConfigurations),
    caseFormInclusions: buildDefaultCaseFormInclusionMap(caseConfig),
  };
}

/**
 * Construct a default filter based on the first available case type reference
 */
function defaultFilter(): ReportFilter {
  return {
    coreTableClauses: {
      cases: [],
      tasks: [],
    },
    coreCaseFormClauses: [],
  };
}

function defaultSettings(appConfig: AppConfiguration): ReportSettings {
  return {
    templateTitle: "Default",
    caseRef: appConfig.caseConfigurations[0].ref,
  };
}

/**
 * The default starting state for the page
 */
const defaultPageState: ReportGenerationPageState = {
  templateDirty: false,
  availableTemplates: [{ name: "Default", value: "Default" }],
  settings: { caseRef: "", templateTitle: "" },
  filter: undefined,
  template: undefined,
  baseLineTemplate: undefined,
  saving: false,
  running: false,
};

/**
 * Page component for the construction and specification or reports and their associated templates
 * @constructor
 */
export const ReportGenerationPage: FC<ReportGenerationPageProps> = () => {
  const appConfig = useAppConfiguration();
  const reportingConfig = useReportingConfiguration();
  const [state, dispatch] = useReducer(reducer, defaultPageState);
  const [debug] = useState<boolean>(false);
  const [, getTemplates] = useAxios({ method: "GET" }, { manual: true });
  const [, getTemplate] = useAxios({ method: "GET" }, { manual: true });
  const [, postTemplate] = useAxios({ method: "POST" }, { manual: true });
  const [, runReport] = useAxios(
    { method: "POST", responseType: "blob" },
    { manual: true },
  );

  // cb which loads a new case configuration and sets up the state accordingly
  const loadCaseConfigurationToState = useCallback(
    (caseRef: string) => {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      dispatch({ type: "setSettingsCaseRef", payload: caseRef });
      dispatch({ type: "setCaseConfig", payload: caseConfig });
      dispatch({
        type: "setTaskConfigs",
        payload: caseTaskConfigurations(appConfig, caseConfig),
      });
      dispatch({
        type: "setSettingsTemplateTitle",
        payload: "Default",
      });
      dispatch({
        type: "setFilter",
        payload: defaultFilter(),
      });
    },
    [appConfig],
  );

  // cb to reset the current template to the default for the current case configuration
  const resetStateTemplateToDefault = useCallback(() => {
    if (state.caseConfig) {
      dispatch({
        type: "setSettingsTemplateTitle",
        payload: "Default",
      });
      dispatch({
        type: "setTemplate",
        payload: defaultTemplate(appConfig, reportingConfig, state.caseConfig),
      });
      dispatch({
        type: "setBaselineTemplate",
        payload: defaultTemplate(appConfig, reportingConfig, state.caseConfig),
      });
    }
  }, [appConfig, reportingConfig, state.caseConfig]);

  // cb to load a stored template from the server
  const loadTemplateToState = useCallback(
    (templateTitle: string) => {
      console.log(`loadTemplate: ${templateTitle}`);
      dispatch({
        type: "setSaving",
        payload: true,
      });
      getTemplate({
        url: `/api/reports/config/templates/${state.settings.caseRef
          }/${encodeURIComponent(templateTitle)}`,
      }).then((response) => {
        console.log(response.data);
        dispatch({
          type: "setTemplate",
          payload: response.data.details,
        });
        dispatch({
          type: "setSettingsTemplateTitle",
          payload: response.data.title,
        });
        dispatch({
          type: "setBaselineTemplate",
          payload: response.data.details,
        });
        dispatch({ type: "setSaving", payload: false });
      });
    },
    [getTemplate, state.settings.caseRef],
  );

  // cb to save the current template. If the save is successful, align the current baseline
  // template state with the saved template
  const saveCurrentTemplate = useCallback(
    (templateTitle: string) => {
      console.log(`saveTemplate: ${templateTitle}`);
      if (state.template) {
        let template = _.cloneDeep(state.template);

        // check whether the name of the template is to be changed (Save As...)
        if (templateTitle !== state.settings.templateTitle) {
          template.name = templateTitle;
        }

        // flag state and dispatch the save
        dispatch({ type: "setSaving", payload: true });
        postTemplate({
          url: `/api/reports/config/templates/${state.settings.caseRef
            }/${encodeURIComponent(templateTitle)}`,
          data: template,
        }).then((response) => {
          console.log("Template saved");
          dispatch({ type: "setSaving", payload: false });
          dispatch({
            type: "setSettingsTemplateTitle",
            payload: templateTitle,
          });
          dispatch({
            type: "setTemplate",
            payload: response.data.details,
          });
          dispatch({
            type: "setBaselineTemplate",
            payload: response.data.details,
          });
        });
      }
    },
    [
      postTemplate,
      state.settings.caseRef,
      state.settings.templateTitle,
      state.template,
    ],
  );

  // cb to load a list of known template names for a given case reference
  const loadTemplateNames = useCallback(
    (caseRef: string) => {
      getTemplates({
        url: `/api/reports/config/templates/${caseRef}`,
      })
        .then((response) => {
          dispatch({
            type: "setAvailableTemplates",
            payload: [{ name: "Default", value: "Default" }].concat(
              response.data.data.map((item: string) => {
                return { name: item, value: item } as NameValuePair;
              }),
            ),
          });
        })
        .catch(() => {
          console.warn("Unexpected response whilst retrieving template list");
        });
    },
    [getTemplates],
  );

  // cb to execute a given report
  const submitReport = useCallback(
    (caseRef: string) => {
      dispatch({
        type: "setRunning",
        payload: true,
      });
      runReport({
        url: `/api/reports/run/${caseRef}`,
        data: {
          template: state.template,
          filter: state.filter,
        },
      }).then((response) => {
        // make sure we keep the history
        const href = URL.createObjectURL(response.data);

        // ghost an anchor element to drive the download
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `${moment(Date()).format("YYYYMMDD-hhmmss")}.xlsx`,
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        // update our local state
        dispatch({
          type: "setRunning",
          payload: false,
        });
      });
    },
    [runReport, state.filter, state.template],
  );

  // this is where most of the action happens, currently just a massive reducer, but then
  // the state managed by this component is pretty complex
  function reducer(
    state: ReportGenerationPageState,
    action: any,
  ): ReportGenerationPageState {
    const { type, payload } = action;
    switch (type) {
      case "markTemplateDirty":
        return { ...state, templateDirty: true };
      case "markTemplateClean":
        return { ...state, templateDirty: false };
      case "setSettings":
        return { ...state, settings: payload };
      case "setFilter":
        return { ...state, filter: payload };
      case "setSaving":
        return { ...state, saving: payload };
      case "setRunning":
        return { ...state, running: payload };
      case "setTemplate":
        return {
          ...state,
          templateDirty: !areObjectsEqual(payload, state.baseLineTemplate),
          template: payload,
        };
      case "setBaselineTemplate":
        return {
          ...state,
          templateDirty: !areObjectsEqual(payload, state.template),
          baseLineTemplate: payload,
        };
      case "setAvailableTemplates":
        return {
          ...state,
          availableTemplates: payload,
        };
      case "setCaseConfig":
        const caseConfig = payload;
        console.log("Switching case type - defaulting");
        return {
          ...state,
          templateDirty: false,
          template: defaultTemplate(appConfig, reportingConfig, caseConfig),
          baseLineTemplate: defaultTemplate(
            appConfig,
            reportingConfig,
            caseConfig,
          ),
          caseConfig: caseConfig,
        };
      case "setTaskConfigs": {
        const taskConfigs = payload;
        return {
          ...state,
          template: {
            ...state.template,
            caseFormInclusions: buildDefaultCaseFormInclusionMap(
              state.caseConfig!!,
            ),
            taskFormInclusions: buildDefaultTaskFormInclusionMap(taskConfigs),
            coreTaskFormFields:
              buildTemplateFormFieldsFromTaskConfigurations(taskConfigs),
          },
          taskConfigs: taskConfigs,
        };
      }
      case "setSettingsCaseRef": {
        return {
          ...state,
          templateDirty: false,
          settings: {
            ...state.settings,
            caseRef: payload,
          },
        };
      }
      case "setSettingsTemplateTitle":
        return {
          ...state,
          settings: { ...state.settings, templateTitle: payload },
        };
      case "setSelectedColumns": {
        if (state.template) {
          const { fieldsKey, columnNames } = payload;
          const mutatedTemplate = mutateTemplateFieldSet(
            state.template,
            fieldsKey,
            updateFieldSelectionStatus(columnNames),
          );
          const newState = {
            ...state,
            templateDirty: !areObjectsEqual(
              state.baseLineTemplate,
              mutatedTemplate,
            ),
            template: mutatedTemplate,
          };
          console.log(`templateDirty = ${newState.templateDirty}`);
          return newState;
        }
        return state;
      }
      case "setColumnLabel": {
        if (state.template) {
          const { fieldsKey, columnName, label } = payload;
          const mutatedTemplate = mutateTemplateFieldSet(
            state.template,
            fieldsKey,
            updateFieldLabel(label, columnName),
          );
          return {
            ...state,
            templateDirty: !areObjectsEqual(
              state.baseLineTemplate,
              mutatedTemplate,
            ),
            template: mutatedTemplate,
          };
        }
        return state;
      }
      case "toggleCaseFormInclusion": {
        const { key } = payload;
        const mutatedTemplate: Partial<ReportTemplate> = {
          ...state.template,
          caseFormInclusions: toggleCaseFormInclusion(state.template!!, key),
        };
        if (state.template) {
          return {
            ...state,
            templateDirty: !areObjectsEqual(
              state.baseLineTemplate,
              mutatedTemplate,
            ),
            template: {
              ...state.template,
              ...mutatedTemplate,
            },
          };
        }
        return state;
      }
      case "toggleTaskFormInclusion": {
        if (state.template) {
          const { key } = payload;
          const mutatedTemplate: Partial<ReportTemplate> = {
            ...state.template,
            taskFormInclusions: toggleTaskFormInclusion(state.template!!, key),
          };
          return {
            ...state,
            templateDirty: !areObjectsEqual(
              state.baseLineTemplate,
              mutatedTemplate,
            ),
            template: {
              ...state.template,
              ...mutatedTemplate,
            },
          };
        }
        return state;
      }
      default:
        console.debug(
          `invalid reducer action received: ${JSON.stringify(action.type)}`,
        );
    }
    return state;
  }

  // set the default template and ensure we've got a selected case config
  useEffect(() => {
    if (appConfig.loaded && reportingConfig.loaded) {
      dispatch({
        type: "setCaseConfig",
        payload: appConfig.caseConfigurations[0],
      });
      dispatch({
        type: "setTaskConfigs",
        payload: caseTaskConfigurations(
          appConfig,
          appConfig.caseConfigurations[0],
        ),
      });
      dispatch({
        type: "setSettings",
        payload: defaultSettings(appConfig),
      });
      dispatch({
        type: "setFilter",
        payload: defaultFilter(),
      });
      dispatch({
        type: "setTemplate",
        payload: defaultTemplate(
          appConfig,
          reportingConfig,
          appConfig.caseConfigurations[0],
        ),
      });
    }
  }, [appConfig, reportingConfig]);

  // use an effect to keep a current list of available templates
  useEffect(() => {
    if (state.settings && state.settings.caseRef) {
      loadTemplateNames(state.settings.caseRef);
    }
  }, [getTemplates, loadTemplateNames, state.settings]);

  return (
    <Page className={undefined}>
      <PageNav
        title="Report Generation"
        children={undefined}
        translate={false}
      />
      {appConfig.loaded && !reportingConfig.loaded && (
        <div>Config not loaded</div>
      )}
      {appConfig.loaded && reportingConfig.loaded && (
        <div className="reports">
          {/* general settings component - bubbles changes to the current template via cb props*/}
          <div className={"panel"}>
            <div className={"section-header"}>
              {GeneralIcons.Settings("section-header-icon")}
              <h3>General</h3>
            </div>
            <div className={"content"}>
              {state.settings && (
                <ReportSettingsEditor
                  updating={state.saving}
                  reportRunning={state.running}
                  saveEnabled={
                    state.templateDirty &&
                    state.settings!!.templateTitle !== "Default"
                  }
                  saveAsEnabled={state.templateDirty}
                  templateNames={state.availableTemplates}
                  settings={state.settings}
                  onCaseRefChanged={(caseRef) => {
                    loadCaseConfigurationToState(caseRef);
                  }}
                  onTemplateTitleChanged={(templateTitle) => {
                    if (templateTitle !== "Default") {
                      loadTemplateToState(templateTitle);
                    } else {
                      resetStateTemplateToDefault();
                    }
                  }}
                  onSaveAsNewTemplate={(templateName) => {
                    saveCurrentTemplate(templateName);
                  }}
                  onSaveTemplate={() => {
                    console.log(`Saving existing template`);
                    saveCurrentTemplate(state.settings.templateTitle);
                  }}
                  onRunReport={() => {
                    if (state.settings.caseRef) {
                      console.log(`Running current report`);
                      submitReport(state.settings.caseRef);
                    }
                  }}
                />
              )}
            </div>
          </div>

          {/* filter editing component - bubbles changes to the current template via cb props*/}
          <div className={"panel"}>
            <div className={"section-header"}>
              {GeneralIcons.Filter("section-header-icon")}
              <h3>Filter</h3>
            </div>
            <div className={"content"}>
              {state.settings && state.template && state.filter && (
                <ReportFilterEditor
                  onFilterUpdate={(additions, removals) => {
                    if (state.filter) {
                      dispatch({
                        type: "setFilter",
                        payload: mutateReportFilter(
                          state.filter,
                          additions,
                          removals,
                        ),
                      });
                    }
                  }}
                  caseConfig={state.caseConfig}
                  filter={state.filter}
                />
              )}
            </div>
          </div>

          {/* template editing component - bubbles changes to the current template via cb props*/}
          <div className={"panel"}>
            <div className={"section-header"}>
              {GeneralIcons.Edit("section-header-icon")}
              <h3>Template Editor</h3>
            </div>
            <div className={"content"}>
              {state.settings && state.template && state.filter && (
                <ReportTemplateEditor
                  caseConfig={state.caseConfig}
                  taskConfigs={state.taskConfigs}
                  template={state.template}
                  onSelectedTableColumnsChange={(fieldsKey, columnNames) =>
                    dispatch({
                      type: "setSelectedColumns",
                      payload: { fieldsKey, columnNames },
                    })
                  }
                  onTableColumnLabelChange={(fieldsKey, columnName, label) =>
                    dispatch({
                      type: "setColumnLabel",
                      payload: { fieldsKey, columnName, label },
                    })
                  }
                  onCaseFormInclusionChange={(key, included) => {
                    dispatch({
                      type: "toggleCaseFormInclusion",
                      payload: { key, included },
                    });
                  }}
                  onTaskFormInclusionChange={(key, included) => {
                    dispatch({
                      type: "toggleTaskFormInclusion",
                      payload: { key, included },
                    });
                  }}
                />
              )}
            </div>
          </div>

          {/* debug stuff only goes here...for dev purposes only*/}
          {debug && (
            <div className={"panel"}>
              <div className={"section-header"}>
                <h3>Debug Goo</h3>
              </div>
              <div className={"content"}>
                <Collapsible
                  expandedTitle={"State"}
                  collapsedTitle={"State"}
                  expanded={false}
                >
                  <ReportStateDebug
                    key={newUUID()}
                    state={{
                      caseConfig: state.caseConfig,
                      taskConfigs: state.taskConfigs,
                      templateDirty: state.templateDirty,
                      availableTemplates: state.availableTemplates,
                    }}
                    settings={state.settings}
                    filter={state.filter}
                    template={state.template}
                    refresh={newUUID()}
                  />
                </Collapsible>
              </div>
            </div>
          )}
        </div>
      )}
    </Page>
  );
};
