import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ModelInstanceView from '../models/ModelInstanceView';
import { useAppState } from '../main/AppState';
import { MarkdownProcessor } from '../shared/MarkdownProcessor';
import { ConditionalFormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import * as AppWidgets from './widgets/AppWidgets';
import { useAuthContext } from '../auth/AuthContextProvider';

export const FormFromTaskConfiguration = ({ model, ...rest }) => {
  let handler = undefined;
  let initialHandlerContext = undefined;
  const formConfig = model.form;
  let {
    schema,
    uiSchema,
    rules,
    guidance,
    actions,
    globalFormFunctions,
    formHandler
  } = formConfig || {};
  const { getTokenSilently } = useAuthContext();

  // we need to inject both the base url and the auth interceptor into
  // any rendered forms, otherwise lookups don't work correctly
  function axiosRequestInterceptor(config) {
    return getTokenSilently()
      .then((token) => {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token.raw}`;
        return config;
      })
      .catch(() => {
        return config;
      });
  }

  // if rules have been supplied by the parent component, use them
  if (rest.rules) {
    rules = rest.rules;
  }

  const extraActions = window.eval(actions) || {};
  const compiledHandler = window.eval(formHandler) || undefined;
  if (compiledHandler && compiledHandler.handler) {
    handler = compiledHandler.handler;
  }
  if (compiledHandler && compiledHandler.context) {
    initialHandlerContext = compiledHandler.context;
  }
  if (globalFormFunctions) {
    window.globalFormFunctions = window.eval(globalFormFunctions) || {};
  }

  const {
    setSidePanelHeaderTitle,
    setActiveSidePanelComponents,
    toggleSidePanelActive
  } = useAppState();
  const ref = useRef();

  const handleEvent = useCallback(
    (e) => {
      if (e.target.tagName === 'A') {
        const href = e.target.href.split(':');
        if (href.length > 1) {
          const isMarkdown = href[0] === 'markdown';
          const file = href[1];
          if (isMarkdown) {
            e.preventDefault();
            const markdownContent = guidance[file];
            setSidePanelHeaderTitle('Guidance');
            setActiveSidePanelComponents(
              <MarkdownProcessor contents={markdownContent} />
            );
            toggleSidePanelActive(true);
          }
        }
      }
    },
    [
      guidance,
      setSidePanelHeaderTitle,
      setActiveSidePanelComponents,
      toggleSidePanelActive
    ]
  );

  useEffect(() => {
    //e.g. anchor link <a href="markdown:filename.md">Link</a>
    const current = ref.current;
    current && current.addEventListener('click', handleEvent);
    return () => {
      current && current.removeEventListener('click', handleEvent);
    };
  }, [handleEvent]);

  if (!model) return null;

  // catch any rules change events and bubble if we have an installed handler
  // components wishing to submit a form will need to submit any dynamic rules
  // so that server-side validation can work properly...
  const bubbleOnRulesChange = (rules, schema, uiSchema) => {
    console.log('bubbling up a rules change');
    if (rest.onRulesChange) {
      rest.onRulesChange(rules, schema, uiSchema);
    }
  };

  return (
    <div ref={ref}>
      <ConditionalFormForSchema
        appWidgets={AppWidgets.appWidgets}
        schema={schema}
        uiSchema={uiSchema}
        formHandler={handler}
        onRulesChange={bubbleOnRulesChange}
        appContext={initialHandlerContext}
        rules={rules ? rules : []}
        extraActions={extraActions}
        axiosBaseUrl={process.env.REACT_APP_SERVER}
        axiosInterceptor={axiosRequestInterceptor}
        {...rest}
      />
    </div>
  );
};

export const FormFromTaskConfigurationWithTitle = ({
  taskRef,
  taskVersion,
  ...rest
}) => {
  const PreviewForm = ({ model }) => {
    const [formData, setFormData] = useState({});
    const updateFormData = (newData) => setFormData(newData);
    const onChange = (newData) => setFormData({ ...newData });

    return (
      <>
        <h2>{model && model.form.schema && model.form.schema.title}</h2>
        <FormFromTaskConfiguration
          model={model}
          values={formData}
          onChange={onChange}
          formContext={{ formData: formData, updateFormData: updateFormData }}
          {...rest}
        />
      </>
    );
  };

  return (
    <ModelInstanceView
      apiUrl={`/api/form/${taskRef}/${taskVersion}`}
      viewItem={PreviewForm}
    />
  );
};

const FormFromTaskRef = ({ taskRef, taskVersion, ...rest }) => {
  return (
    <ModelInstanceView
      apiUrl={`/api/form/${taskRef}/${taskVersion}`}
      viewItem={FormFromTaskConfiguration}
      {...rest}
    />
  );
};

FormFromTaskRef.propTypes = {
  taskRef: PropTypes.string.isRequired,
  taskVersion: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  values: PropTypes.object
};

export default FormFromTaskRef;
