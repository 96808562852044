import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./main/App";
import "./config/MomentConfig";
import { flavour } from "./config";
import { AuthContextProvider } from "./auth/AuthContextProvider";
import { PusherProvider } from "./pusher/PusherContext";
import { AppStateProvider } from "./main/AppState";
import { AirbrakeErrorBoundary } from "./Airbrake";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiePopup } from "./cookies/popup";
import { AppConfigurationProvider } from "./shared/contexts/AppConfiguration";
import { ReportingConfigurationProvider } from "./shared/contexts/ReportingConfiguration";

const onRedirectCallback = (appState) => {
  window.location.replace(
    appState && appState.targetUrl ? appState.targetUrl : "/dashboard",
  );
};

const onIntlError = (error) => {
  if (process.env.NODE_ENV !== "production") {
    console.warn(error);
  }
};

if (process.env.REACT_APP_DEBUG_ENV === "true") {
  console.log(JSON.stringify(process.env, null, 2));
  window.__env__ = process.env;
}

ReactDOM.render(
  <AirbrakeErrorBoundary>
    <IntlProvider locale="en" messages={flavour.messages} onError={onIntlError}>
      <AppConfigurationProvider>
        <AuthContextProvider
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <PusherProvider>
            <AppStateProvider>
              <ReportingConfigurationProvider>
                <App />
                <ToastContainer />
                <CookiePopup />
              </ReportingConfigurationProvider>
            </AppStateProvider>
          </PusherProvider>
        </AuthContextProvider>
      </AppConfigurationProvider>
    </IntlProvider>
  </AirbrakeErrorBoundary>,
  document.getElementById("root"),
);
