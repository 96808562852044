import { DropdownButton } from "react-bootstrap";
import { useCaseConfigurations } from "../shared/hooks/AppConfigurationHooks";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { caseOrTaskMessage, UiMessageKey } from "../shared/types";

/**
 * Simple bar component that allows a user to create a new instance of a selected case type
 */
export const DashboardCaseCreationBar = () => {
  const caseConfigs = useCaseConfigurations();
  return (
    <div className="dashboard-header">
      <div className="dashboard-actions">
        <DropdownButton title="Create new...">
          {caseConfigs.map((item) => (
            <DropdownItem
              className="dashboard-create-link"
              href={`/assessment/${item.ref}`}
            >
              {caseOrTaskMessage(item, UiMessageKey.TitleSingular)}
            </DropdownItem>
          ))}
        </DropdownButton>
      </div>
    </div>
  );
};
