import React from "react";
import { useAuthContext } from "../auth/AuthContextProvider";
import { TasksAssignedCard } from "../charts/tasksAssigned";
import { WithPermission } from "../auth/AccessConditionals";
import { CasesPerDayCard } from "../charts/newCasesEachDay";
import { Page, PageNav } from "../shared/Page";
import { useAppState } from "../main/AppState";
import { CasesDueForReviewCard } from "../charts/casesDueForReview";
import { CaseStatusesStackedBarChart } from "../charts/CaseStatusesStackedBarChart";
import { newUUID } from "../shared/functions";
import { CardWidget } from "../shared/CardWidget";
import { DashboardCaseCreationBar } from "./DashboardCaseCreationBar";

/**
 * @return {JSX.Element}
 */
export default function DashboardDefault() {
  const { isAuthenticated } = useAuthContext();
  const { hasTaskForms } = useAppState();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Page>
      <PageNav title="nav.dashboard" />
      <WithPermission name="case:create">
        <DashboardCaseCreationBar />
      </WithPermission>
      <WithPermission name="case:read">
        <div className="row align-items-start">
          <div className="col">
            <CasesPerDayCard />
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col">
            <CasesDueForReviewCard />
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col">
            <CardWidget title="Case status" navItems={undefined}>
              <CaseStatusesStackedBarChart refreshCookie={newUUID()} />
            </CardWidget>
          </div>
        </div>
      </WithPermission>
      {hasTaskForms && (
        <div className="row align-items-start">
          <div className="col-md-6">
            <TasksAssignedCard />
          </div>
          <div className="col-md-6"></div>
        </div>
      )}
    </Page>
  );
}
